import { AsyncState } from "hooks/use_async_fn";
import { useAsyncReducer } from "hooks/use_async_reducer";
import { useConfig } from "providers/config";
import { useCallback, useReducer } from "react";
import useSWR from "swr";
import { Coordinates } from "../coordinates";
import { MapboxFeature, MapboxFeatureCollection } from "../mapbox";

interface ReverseGeocodingResult {
  data: MapboxFeature | undefined;
  loading: boolean;
  error: Error | undefined;
}

export function useReverseGeocodingQuery(
  coordinates: Coordinates | undefined,
): ReverseGeocodingResult {
  const { mapboxApiAccessToken } = useConfig();

  const searchParams = new URLSearchParams();

  searchParams.set("types", "place");
  searchParams.set("access_token", mapboxApiAccessToken);

  const { data, error } = useSWR<MapboxFeatureCollection>(
    coordinates
      ? `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lng},${
          coordinates.lat
        }.json?${searchParams.toString()}`
      : null,
  );

  if (data) {
    const firstFeature = {
      ...data.features?.[0],
      bbox: calculateBoundingBox(
        data.features?.[0].center[1],
        data.features?.[0].center[0],
        8,
      ),
    };

    if (firstFeature) {
      return {
        data: firstFeature,
        loading: false,
        error: undefined,
      };
    }
  }

  return {
    data: undefined,
    loading: !!(coordinates && !data && !error),
    error: error,
  };
}

export function useReverseGeocodingLazyQuery(): [
  (coordinates: Coordinates) => Promise<MapboxFeature>,
  AsyncState<MapboxFeature>,
] {
  const { mapboxApiAccessToken } = useConfig();
  const reducer = useAsyncReducer<MapboxFeature>();
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    loading: false,
    error: undefined,
  });

  const query = useCallback(
    async (coordinates: Coordinates) => {
      dispatch({ type: "ASYNC_REQUEST" });
      const searchParams = new URLSearchParams();

      searchParams.set("types", "place");
      searchParams.set("access_token", mapboxApiAccessToken);

      const res = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lng},${
          coordinates.lat
        }.json?${searchParams.toString()}`,
      );

      const body = await res.json();

      if (res.status >= 300) {
        const error = new Error(body.message);
        dispatch({ type: "ASYNC_FAILED", error });
        throw error;
      }

      dispatch({ type: "ASYNC_SUCCESS", data: body });
      const firstFeature = {
        ...body.features[0],
        bbox: calculateBoundingBox(
          body.features?.[0].center[1],
          body.features?.[0].center[0],
          8,
        ),
      } as MapboxFeature;

      if (!firstFeature) {
        throw new Error("could not find place");
      }

      return firstFeature;
    },
    [mapboxApiAccessToken],
  );

  return [query, state];
}

// The bounding box returned from API is not accurate enough for our use case
// It is a square box around the center of the place, rather than user's coordinates
// This function calculates a more accurate bounding box based on the distance
export function calculateBoundingBox(
  lat: number,
  lng: number,
  distanceInKm: number,
): [number, number, number, number] {
  const earthRadius = 6371; // Radius of the Earth in kilometers

  const latDiff = (distanceInKm / earthRadius) * (180 / Math.PI);
  const lngDiff =
    (distanceInKm / (earthRadius * Math.cos((Math.PI * lat) / 180))) *
    (180 / Math.PI);

  const minLat = lat - latDiff;
  const maxLat = lat + latDiff;
  const minLng = lng - lngDiff;
  const maxLng = lng + lngDiff;

  return [minLng, minLat, maxLng, maxLat];
}
