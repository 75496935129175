import { gql, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import {
  AppHeader,
  DESKTOP_APP_HEADER_HEIGHT,
  MOBILE_APP_HEADER_HEIGHT,
} from "components/app_header_v3/app_header";
import { BottomDrawer } from "components/bottom_drawer/bottom_drawer";
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Dialog } from "components/dialog";
import { Icon } from "components/icon";
import { Spacer } from "components/spacer";
import { Text } from "components/text_v2";
import { BottomBarNavigation } from "core/bottom_bar_navigation";
import { Footer } from "core/footer";
import {
  HomePage__LocationDetailsFragment,
  HomePage__SpaceDetailsFragment,
  HomePageLocations__LocationDetailsFragment,
  HomePageLocationsQuery,
  HomePageLocationsQueryVariables,
  HomePageQuery,
  HomePageQueryVariables,
  SpaceType,
} from "core/graphql.generated";
import { isBefore, toDay, today } from "lib/day_utils";
import { calculateBoundingBoxRadius } from "lib/map_utils";
import { useMediaQuery } from "lib/media_query";
import { useAnalytics } from "providers/analytics";
import {
  useGCalTileFeatureFlag,
  useOptimizeSearchFeatureFlag,
  useReBookFeatureFlag,
} from "providers/splitio";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";
import { LocationPreview } from "./components/location_preview";
import { HomeMap } from "./components/map";
import { SearchErrorContainer } from "./components/search_error_container";
import { SpaceListContainer } from "./components/space_list_container";
import { SpaceTypeFilter } from "./components/space_type_filters";
import { useAmenitySearchFilterAnaltics } from "./hooks/use_amenity_search_filter_analytics";
import { useCurrentUserGeoCoordinatesQuery } from "./hooks/use_current_user_geo_coordinates";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "./hooks/use_home_search_params";
import { useInitialHomeSearchParams } from "./hooks/use_initial_home_search_params";
import { useLastLocationSearch } from "./hooks/use_last_location_search";

enum HomeViewModes {
  List = 1,
  Map = 2,
}

export type OnPreviewLocation = (
  location: Pick<
    HomePage__LocationDetailsFragment,
    "timezone" | "id" | "images" | "name" | "address"
  >,
  spaceId?: string,
) => void;

export function HomePage() {
  const {
    coordinates,
    bbox,
    date,
    startTime,
    endTime,
    minCapacity,
    maxCapacity,
    amenities,
    preferred,
    spaceType,
    sort,
    page,
  } = useHomeSearchParamsQuery();

  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();

  const optimizeSearchFeatureFlag = useOptimizeSearchFeatureFlag();
  const reBookFeatureFlag = useReBookFeatureFlag();
  useInitialHomeSearchParams();
  useAmenitySearchFilterAnaltics();
  const mq = useMediaQuery();
  const history = useHistory();
  const { data: currentUserCoordinates, loading: currentUserLoading } =
    useCurrentUserGeoCoordinatesQuery();
  const gCalTileFeatureFlag = useGCalTileFeatureFlag();
  const [locationPreviewData, setLocationPreviewData] = useState<Pick<
    HomePage__LocationDetailsFragment,
    "timezone" | "id" | "images" | "name" | "address"
  > | null>(null);
  const [isRefetching, setIsRefetching] = useState(false);

  useEffect(() => {
    if (date && isBefore(date, toDay(new Date()))) {
      updateHomeSearchParams({
        date: null,
        startTime: null,
        endTime: null,
      });
    }
  }, [date, updateHomeSearchParams]);

  /**
   * To apply search when we go back to home button the search will be kept inside the recoil state
   * So that user will not loose it
   */
  useLastLocationSearch();

  let cardsPerPage = 100;

  if (spaceType === SpaceType.MeetingRoom && gCalTileFeatureFlag) {
    cardsPerPage = cardsPerPage - 1;
  } else if (spaceType === SpaceType.DayOffice) {
    cardsPerPage = cardsPerPage / 2;
  }

  const variables: HomePageQueryVariables | null = useMemo(() => {
    if (!coordinates) {
      return null;
    }

    if (!currentUserCoordinates && currentUserLoading) {
      return null;
    }

    return {
      centerMapCoordinates: {
        lat: coordinates?.lat,
        lng: coordinates?.lng,
      },
      currentUserCoordinates: currentUserCoordinates
        ? {
            lat: currentUserCoordinates?.lat,
            lng: currentUserCoordinates?.lng,
          }
        : {},

      filter: {
        date,
        startTime,
        endTime,
        minCapacity,
        maxCapacity,
        amenities,
        preferred,
      },
      spaceType: spaceType!,
      limit: cardsPerPage,
      offset: cardsPerPage * (page - 1),
      sort: sort,
      bookingsDate: date || today(),
      radius:
        optimizeSearchFeatureFlag && bbox
          ? calculateBoundingBoxRadius(bbox)
          : undefined,
    };
  }, [
    coordinates,
    currentUserCoordinates,
    currentUserLoading,
    date,
    startTime,
    endTime,
    minCapacity,
    maxCapacity,
    amenities,
    preferred,
    spaceType,
    cardsPerPage,
    page,
    sort,
    optimizeSearchFeatureFlag,
    bbox,
  ]);

  const locationVariables: HomePageLocationsQueryVariables | null =
    useMemo(() => {
      if (!coordinates) {
        return null;
      }

      if (!currentUserCoordinates && currentUserLoading) {
        return null;
      }

      return {
        centerMapCoordinates: {
          lat: coordinates?.lat || currentUserCoordinates?.lat,
          lng: coordinates?.lng || currentUserCoordinates?.lng,
        },

        filter: {
          date,
          amenities,
          preferred,
        },
      };
    }, [
      amenities,
      coordinates,
      currentUserCoordinates,
      currentUserLoading,
      date,
      preferred,
    ]);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);
  const {
    data,
    loading: spacesLoading,
    previousData,
    refetch: refetchSpaces,
    error: spacesQueryError,
  } = useQuery<HomePageQuery, HomePageQueryVariables>(homePageQuery, {
    variables: variables!,
    skip: !variables || !spaceType,
    notifyOnNetworkStatusChange: false,
    onError: (error: any) => {
      Sentry.configureScope((scope) => {
        scope.setTransactionName("HomePageQuery");
        scope.setExtra("originalError", error);
      });
      Sentry.captureException(error);
    },
  });

  const {
    data: locationsData,
    loading: locationsLoading,
    refetch: refetchLocations,
    error: locationsQueryError,
  } = useQuery<HomePageLocationsQuery, HomePageLocationsQueryVariables>(
    homePageLocationsQuery,
    {
      variables: locationVariables!,
      skip: !reBookFeatureFlag || !locationVariables || !!spaceType,
      notifyOnNetworkStatusChange: false,
      onError: (error: any) => {
        Sentry.configureScope((scope) => {
          scope.setTransactionName("HomePageQuery");
          scope.setExtra("originalError", error);
        });
        Sentry.captureException(error);
      },
    },
  );

  const loading =
    spacesLoading ||
    currentUserLoading ||
    !coordinates ||
    locationsLoading ||
    isRefetching;
  const handlePreviewLocation = useCallback(
    (
      location: Pick<
        HomePage__LocationDetailsFragment,
        "timezone" | "id" | "images" | "name" | "address"
      >,
      spaceId?: string,
    ) => {
      if (spaceId || !spaceType) {
        let search = "";
        if (date) {
          search += `?startDate=${date}&endDate=${date}`;
        }

        if (!spaceType) {
          history.push(`/location/${location.id}`);
        } else {
          history.push(`/offsite_spaces/${spaceId}${search}`);
        }
      } else {
        setLocationPreviewData(location);
      }
    },
    [date, history, spaceType],
  );

  const totalCount = data?.spaces.totalCount;
  const totalPages = totalCount
    ? Math.ceil(totalCount / cardsPerPage)
    : undefined;

  const refetchOnError = async () => {
    setIsRefetching(true);

    try {
      if (spaceType) {
        await refetchSpaces(variables!);

        setIsRefetching(false);
        return;
      }

      await refetchLocations(locationVariables!);
      setIsRefetching(false);
    } catch (error) {
      Sentry.captureException(error);
      setIsRefetching(false);
    }
  };

  const isSearchError = spacesQueryError || locationsQueryError;

  return (
    <View testID="home-page">
      {mq.deviceQuery.mobile ? (
        <HomeBodyMobile
          data={data?.spaces.data || []}
          locations={locationsData?.locations}
          previousData={previousData?.spaces.data || []}
          loading={loading}
          onPreviewLocation={handlePreviewLocation}
          totalPages={totalPages}
          totalCount={totalCount}
          isSearchError={!!isSearchError}
          refetchFunc={refetchOnError}
        />
      ) : (
        <HomeBodyDesktop
          data={data?.spaces.data || []}
          locations={locationsData?.locations}
          previousData={previousData?.spaces.data || []}
          loading={loading}
          onPreviewLocation={handlePreviewLocation}
          totalPages={totalPages}
          totalCount={totalCount}
          isSearchError={!!isSearchError}
          refetchFunc={refetchOnError}
        />
      )}
      {locationPreviewData && (
        <Dialog visible onRequestClose={() => setLocationPreviewData(null)}>
          <LocationPreview
            locationPreloaded={locationPreviewData}
            onClose={() => setLocationPreviewData(null)}
          />
        </Dialog>
      )}
    </View>
  );
}

interface HomeBodyProps {
  data: HomePage__SpaceDetailsFragment[];
  previousData: HomePage__SpaceDetailsFragment[];
  onPreviewLocation: OnPreviewLocation;
  locations?: HomePageLocations__LocationDetailsFragment[];
  loading: boolean;
  totalPages: number | undefined;
  totalCount: number | undefined;
  isSearchError?: boolean;
  refetchFunc?: () => void;
}

function HomeBodyDesktop(props: HomeBodyProps) {
  const {
    data,
    previousData,
    onPreviewLocation,
    loading,
    totalPages,
    totalCount,
    locations,
    isSearchError,
    refetchFunc,
  } = props;
  const [listCollapsed, setListCollapsed] = useState(false);
  const analytics = useAnalytics();
  const handleCollapse = useCallback(() => {
    analytics.event("View Full Map");
    setListCollapsed(true);
  }, [analytics]);
  const handleExpand = useCallback(() => {
    setListCollapsed(false);
  }, []);

  // If there is no data, use the previous data for map
  const mapData = !loading && data ? data : previousData;

  return (
    <Fragment>
      <View style={styles.headerWrapper}>
        <AppHeader withSearch />
      </View>

      <View style={styles.contentContainer}>
        {!listCollapsed && (
          <View style={[styles.listWrapper, { paddingTop: 0 }]}>
            <View style={styles.desktopFilterContainer}>
              <View style={styles.desktopFiltersPadding}>
                <SpaceTypeFilter />
                <Spacer size={16} />
              </View>
            </View>
            {!!isSearchError && !loading && (
              <SearchErrorContainer refetchFunc={refetchFunc} />
            )}
            {(loading || (!loading && !isSearchError)) && (
              <SpaceListContainer
                showSpaceTypeTabs={true}
                onPreviewLocation={onPreviewLocation}
                spaces={data}
                loading={loading}
                totalPages={totalPages}
                totalCount={totalCount}
                locations={locations}
              />
            )}
            <Spacer size={80} />
          </View>
        )}
        <View style={styles.mapWrapper}>
          <View style={styles.mapControlsWrapper}>
            <CollapseButton
              onPress={listCollapsed ? handleExpand : handleCollapse}
              collapsed={listCollapsed}
            />

            {listCollapsed && (
              <Fragment>
                <Spacer size={8} direction="row" />
                <SpaceTypeFilter />
              </Fragment>
            )}
          </View>
          <HomeMap
            dayPasses={mapData}
            meetingRooms={mapData}
            dayOffices={mapData}
            loading={loading}
            onPreviewLocation={onPreviewLocation}
            locations={locations}
          />
        </View>
      </View>
      <Footer />
    </Fragment>
  );
}

interface CollapseButtonProps {
  onPress: () => void;
  collapsed: boolean;
}

function CollapseButton(props: CollapseButtonProps) {
  const { onPress, collapsed } = props;

  return (
    <Pressable
      style={[styles.collapseButton, !collapsed && styles.collapseExpanded]}
      onPress={onPress}
    >
      <Icon
        name={collapsed ? "chevron-right" : "chevron-left"}
        size="md"
        color={colors.brand.blackMinus30}
      />
      {collapsed && <Text size="xs">Show List</Text>}
    </Pressable>
  );
}

function HomeBodyMobile(props: HomeBodyProps) {
  const {
    data,
    previousData,
    onPreviewLocation,
    loading,
    totalPages,
    totalCount,
    locations,
    isSearchError,
    refetchFunc,
  } = props;
  const [view, changeView] = useState(HomeViewModes.List);
  const analytics = useAnalytics();
  const setView = (newView: HomeViewModes) => {
    if (newView === HomeViewModes.Map) {
      analytics.event("View Full Map");
    }
    changeView(newView);
  };

  // If there is no data, use the previous data for map
  const mapData = !loading && data ? data : previousData;
  const isSuccess = !loading && !isSearchError;
  const isFailed = !loading && isSearchError;

  return (
    <Fragment>
      <View style={styles.headerWrapper}>
        <AppHeader withSearch />
      </View>

      <View
        style={[
          styles.mobileSpaceTabsWrapper,
          styles.amenityModuleMobileHeader,
        ]}
      >
        <SpaceTypeFilter />
      </View>
      <View style={styles.contentContainer}>
        {isFailed && <SearchErrorContainer refetchFunc={refetchFunc} />}
        {(loading || isSuccess) && view === HomeViewModes.List && (
          <View style={styles.listWrapper}>
            <SpaceListContainer
              onPreviewLocation={onPreviewLocation}
              spaces={data}
              totalCount={totalCount}
              loading={loading}
              totalPages={totalPages}
              locations={locations}
            />
            <Spacer size={80} />
          </View>
        )}

        {(loading || isSuccess) && view === HomeViewModes.Map && (
          <BottomDrawer
            onExpand={() => {
              setView(HomeViewModes.List);
            }}
          >
            <SpaceListContainer
              onPreviewLocation={onPreviewLocation}
              spaces={data ?? []}
              locations={locations}
              loading={loading}
              totalPages={totalPages}
              totalCount={totalCount}
            />
          </BottomDrawer>
        )}

        {(loading || isSuccess) && view === HomeViewModes.Map && (
          <View style={styles.mapWrapper}>
            <HomeMap
              dayPasses={mapData}
              meetingRooms={mapData}
              dayOffices={mapData}
              loading={loading}
              locations={locations}
              onPreviewLocation={onPreviewLocation}
            />
          </View>
        )}
      </View>
      {(loading || isSuccess) && view === HomeViewModes.List && (
        <View style={styles.buttonWrapper}>
          <View style={styles.viewModeButton}>
            <Button
              text="Map"
              iconName="map-view"
              onPress={() => setView(HomeViewModes.Map)}
            />
          </View>
        </View>
      )}
      <BottomBarNavigation />
    </Fragment>
  );
}

const styles = StyleSheet.create({
  headerWrapper: {
    zIndex: 2,
    // @ts-ignore
    position: "sticky",
    background: "white",
    left: 0,
    top: 0,
    right: 0,
  },
  listWrapper: {
    flex: 1,
    maxWidth: 1000,
    paddingTop: 16,
  },
  mapControlsWrapper: {
    position: "absolute",
    top: 16,
    left: 16,
    zIndex: 10,
    flexDirection: "row",
    height: 38,
  },
  mapWrapper: {
    flex: 1,
    // @ts-ignore
    position: "sticky",
    top: DESKTOP_APP_HEADER_HEIGHT,
    height: `calc(100vh - ${DESKTOP_APP_HEADER_HEIGHT}px)`,
  },
  contentContainer: {
    flexDirection: "row",
    flex: 1,
    display: "flex",
  },
  buttonWrapper: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    // @ts-ignore
    position: "fixed",
    bottom: 76,
    left: 0,
    right: 0,
  },
  viewModeButton: {
    minWidth: 114,
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 6,
    borderRadius: 5,
  },
  collapseButton: {
    backgroundColor: colors.brand.whitecore,
    borderWidth: 1,
    borderColor: "#E6E5E8",
    borderRadius: 4,
    minWidth: 32,
    minHeight: 32,
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 4,
    paddingRight: 4,
    height: "100%",
  },
  collapseExpanded: {
    paddingRight: 8,
  },
  mapSpaceTabsWrapper: {
    width: "95vw",
    maxWidth: 343,
  },

  mobileSpaceTabsWrapper: {
    backgroundColor: colors.brand.whitecore,
    paddingHorizontal: 16,
    paddingVertical: 8,
    // @ts-ignore
    position: "sticky",
    top: MOBILE_APP_HEADER_HEIGHT,
    zIndex: 1,
    ...colors.shadow.header,
  },
  amenityModuleMobileHeader: {
    top: MOBILE_APP_HEADER_HEIGHT,
  },
  desktopFilterContainer: {
    backgroundColor: colors.brand.whitecore,
    // @ts-ignore
    position: "sticky",
    top: DESKTOP_APP_HEADER_HEIGHT,
    zIndex: 1,
    height: 72,
    ...colors.shadow.header,
  },
  desktopFiltersPadding: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
});

export const homePageQuery = gql`
  query HomePage(
    $spaceType: SpaceType!
    $filter: SpacesFilter
    $centerMapCoordinates: SpaceSearchCoordinates
    $currentUserCoordinates: SpaceSearchCoordinates
    $limit: Int
    $offset: Int
    $radius: Int
    $sort: SpacesSort
    $bookingsDate: String!
  ) {
    spaces(
      spaceType: $spaceType
      filter: $filter
      centerMapCoordinates: $centerMapCoordinates
      currentUserCoordinates: $currentUserCoordinates
      limit: $limit
      offset: $offset
      radius: $radius
      sort: $sort
    ) {
      totalCount
      data {
        ...HomePage__SpaceDetails
      }
    }
  }
  fragment HomePage__SpaceDetails on Space {
    id
    name
    currency
    maxCapacity
    locked
    preferred
    savedByCurrentUser
    amenities {
      id
      iconURL
      name
      code
    }
    location {
      ...HomePage__LocationDetails
    }
    physicalSpacesCount
    physicalSpaces(first: 1) {
      id
      name
      floor
      withWindow
    }
    images {
      small {
        url
        width
        height
      }
    }
    pricings {
      type
      price
    }
    priceRanges {
      daily {
        min
        max
      }
      hourly {
        min
        max
      }
    }
  }
  fragment HomePage__LocationDetails on Location {
    id
    name
    timezone
    centerMapDistance: distance(coordinates: $centerMapCoordinates)
    currentUserDistance: distance(coordinates: $currentUserCoordinates)
    bookings(startDate: $bookingsDate, endDate: $bookingsDate) {
      id
      startTime
      endTime
      user {
        id
        userId
        picture
        fullName
        title
        email
      }
    }
    amenities {
      id
      name
      iconURL
      code
    }
    images {
      small {
        url
        width
        height
      }
    }
    address {
      city
      streetAddress
      latitude
      longitude
    }
  }
`;

const homePageLocationsQuery = gql`
  query HomePageLocations(
    $filter: LocationsFilter
    $centerMapCoordinates: SpaceSearchCoordinates
  ) {
    locations(filter: $filter, centerMapCoordinates: $centerMapCoordinates) {
      ...HomePageLocations__LocationDetails
    }
  }
  fragment HomePageLocations__LocationDetails on Location {
    id
    name
    timezone
    savedByCurrentUser
    preferred
    amenities {
      id
      name
      iconURL
      code
    }
    images {
      small {
        url
        width
        height
      }
    }
    address {
      city
      streetAddress
      latitude
      longitude
    }
  }
`;
