/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { DialogModal } from "components/dialog_modal";
import {
  HomeSearchParamsChangeSet,
  useUpdateHomeSearchParamsMutation,
} from "pages/homev2/hooks/use_home_search_params";

import { DatePickerMobile } from "./date_picker_mobile";
import { SearchLocationMobile } from "./search_location_mobile";
import { SpaceTypePickerMobile } from "./space_type_picker_mobile";

interface SearchStep {
  title: string;
  content: JSX.Element;
}

interface SearchModalMobileProps {
  isVisible: boolean;
  onClose: () => void;
  onSearch?: (field: string) => void;
}

export function SearchModalMobile({
  isVisible,
  onClose,
  onSearch,
}: SearchModalMobileProps) {
  const history = useHistory();
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();

  const [stepNumber, setStepNumber] = useState(0);
  const [searchParams, setSearchParams] = useState<HomeSearchParamsChangeSet>();
  const [searchPlace, setSearchPlace] = useState("");

  const handleShowResults = () => {
    onClose();
    if (searchParams) {
      updateHomeSearchParams(searchParams);
      history.push(`/search${location.search}`);
    }
  };

  const handleSearchLocation = (
    value: HomeSearchParamsChangeSet,
    searchPlace: string,
  ) => {
    setSearchParams((v) => ({ ...v, ...value }));
    setSearchPlace(searchPlace);
    setStepNumber(1);
    if (onSearch) {
      onSearch("Location");
    }
  };

  const handlePickSpaceType = (value: HomeSearchParamsChangeSet) => {
    setSearchParams((v) => ({ ...v, ...value }));
    setStepNumber(2);
    if (onSearch) {
      onSearch("Space type");
    }
  };

  const searchSteps: SearchStep[] = [
    {
      title: "Where do you want to work?",
      content: (
        <SearchLocationMobile
          searchPlace={searchPlace}
          onChange={handleSearchLocation}
        />
      ),
    },
    {
      title: "What space are you looking for?",
      content: (
        <SpaceTypePickerMobile
          value={searchParams?.spaceType || undefined}
          onContinue={handleShowResults}
        />
      ),
    },
    // {
    //   title: "When do you need a space?",
    //   content: (
    //     <DatePickerMobile
    //       value={searchParams?.date || undefined}
    //       onChange={(value) => {
    //         setSearchParams((v) => ({ ...v, ...value }));
    //         if (onSearch) {
    //           onSearch("Date");
    //         }
    //       }}
    //       onShowResults={handleShowResults}
    //     />
    //   ),
    // },
  ];

  return (
    <DialogModal
      isVisible={isVisible}
      onClose={onClose}
      showCloseButton={stepNumber === 0}
      headerTitle={searchSteps[stepNumber].title}
      noScroll
      headerLeftIcon={stepNumber !== 0 ? "chevron-left" : undefined}
      onHeaderLeftIconPress={() => setStepNumber((v) => v - 1)}
    >
      {searchSteps[stepNumber].content}
    </DialogModal>
  );
}
