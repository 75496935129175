/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "components/button_v2";
import { colors } from "components/colors";
import { Divider } from "components/divider";
import { SpaceType } from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import {
  useHomeSearchParamsQuery,
  useUpdateHomeSearchParamsMutation,
} from "pages/homev2/hooks/use_home_search_params";
import { useAnalytics } from "providers/analytics";
import React, { useCallback, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useHistory, useLocation } from "react-router-dom";
import { HomeSearchInput } from "./home_search_input";
import { HeaderDatePickerV1 } from "../../../components/app_header_v3/header_date_picker_v1";
import { HeaderSpaceTypePickerV1 } from "../../../components/app_header_v3/header_space_type_picker_v1";
import { Icon } from "components/icon";
import { Text } from "components/text_v2";
import { SearchModalMobile } from "./search_modal_mobile";

interface HomeSearchBarProps {
  onFilterChange?: (field: string) => void;
}

export function HomeSearchBar(props: HomeSearchBarProps) {
  const { onFilterChange } = props;
  const mq = useMediaQuery();
  return mq.deviceQuery.mobile ? (
    <SearchBarMobile onFilterChange={onFilterChange} />
  ) : (
    <SearchBarDesktop onFilterChange={onFilterChange} />
  );
}

function SearchBarDesktop(props: HomeSearchBarProps) {
  const { onFilterChange } = props;
  const { spaceType, date } = useHomeSearchParamsQuery();
  const history = useHistory();
  const location = useLocation();
  const analytics = useAnalytics();
  const updateHomeSearchParams = useUpdateHomeSearchParamsMutation();

  const handleSpaceTypeChange = useCallback(
    (value: SpaceType | undefined) => {
      updateHomeSearchParams({
        spaceType: value || null,
        startTime: null,
        endTime: null,
        minCapacity: null,
        maxCapacity: null,
        amenities: null,
      });
      analytics.event("Select Space Type", {
        "Space Type": value || "any",
      });

      if (onFilterChange) {
        onFilterChange("Space type");
      }
    },
    [analytics, onFilterChange, updateHomeSearchParams],
  );

  const handleDateChange = useCallback(
    (value: string) => {
      updateHomeSearchParams({
        date: value || null,
      });
      analytics.event("Select Date", {
        Date: value,
      });
      if (onFilterChange) {
        onFilterChange("Date");
      }
    },
    [analytics, onFilterChange, updateHomeSearchParams],
  );
  const onSearch = () => {
    const { search } = location;
    history.push(`/search${search}`);
  };
  return (
    <View style={styles.container}>
      <View style={styles.searchInput}>
        <HomeSearchInput onSearch={onFilterChange} />
      </View>
      <Divider direction={"vertical"} />
      <HeaderSpaceTypePickerV1
        onChange={handleSpaceTypeChange}
        value={spaceType}
      />
      {/* <Divider direction={"vertical"} />
      <HeaderDatePickerV1 value={date} onChange={handleDateChange} /> */}
      <View style={styles.bntView}>
        <Button
          appearance={"primary"}
          size={"small"}
          text={"Search"}
          iconName={"search"}
          onPress={onSearch}
        />
      </View>
    </View>
  );
}

function SearchBarMobile(props: HomeSearchBarProps) {
  const { onFilterChange } = props;

  const [isVisible, setIsVisible] = useState(false);
  return (
    <View style={styles.containerMobile}>
      <Pressable onPress={() => setIsVisible(true)} style={styles.searchBar}>
        <Icon name="magnifying-glass" />
        <Text size="xs" color="black-70">
          Where are you working?
        </Text>
      </Pressable>
      <SearchModalMobile
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        onSearch={onFilterChange}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: colors.brand.whitecore,
    borderRadius: 4,
  },
  containerMobile: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: colors.brand.whitecore,
  },
  searchInput: {
    flex: 2,
  },
  searchInputMobile: {
    width: "100%",
  },
  dateAndSpaceTypeFilters: {
    // paddingVertical: 8,
    flexDirection: "row",
  },
  pickerContainer: {
    width: "calc(50% - 4px)",
  },
  bntView: {
    padding: 12,
  },
  searchBar: {
    paddingVertical: 10,
    paddingHorizontal: 8,
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
});
