/* eslint-disable @typescript-eslint/no-unused-vars */
import { gql, useQuery } from "@apollo/client";
import { Spacer } from "components/spacer";
import {
  Amenity,
  SpaceType,
  SpaceTypeFilterAmenitiesQuery,
  SpaceTypeFilterAmenitiesQueryVariables,
} from "core/graphql.generated";
import { useMediaQuery } from "lib/media_query";
import { usePreferredSpaceFeatureFlag } from "providers/splitio";
import { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { useHomeSearchParamsQuery } from "../hooks/use_home_search_params";
import { AmenitiesPicker } from "./amenities_picker";
import { CapacityFilter } from "./capacity_filter";
import { StartEndTimePicker } from "./start_end_time_picker";

interface SpaceTypeFilterProps {
  spaceTypeAmenities?: Amenity[];
  locationAmenities?: Amenity[];
}

export function SpaceTypeFilter() {
  const { spaceType } = useHomeSearchParamsQuery();
  const { data } = useQuery<
    SpaceTypeFilterAmenitiesQuery,
    SpaceTypeFilterAmenitiesQueryVariables
  >(amenitiesQuery);

  const spaceAmenities = useMemo(
    () =>
      spaceType === SpaceType.DayPass
        ? data?.DayPassAmenities
        : spaceType === SpaceType.DayOffice
        ? data?.DayOfficeAmenities
        : spaceType === SpaceType.MeetingRoom
        ? data?.MeetingRoomAmenities
        : [],
    [
      data?.DayOfficeAmenities,
      data?.DayPassAmenities,
      data?.MeetingRoomAmenities,
      spaceType,
    ],
  );

  const locationAmenities = useMemo(
    () => data?.LocationAmenities,
    [data?.LocationAmenities],
  );

  if (!data) {
    return null;
  } else if (spaceType === SpaceType.MeetingRoom) {
    return (
      <MeetingRoomFilter
        spaceTypeAmenities={spaceAmenities}
        locationAmenities={locationAmenities}
      />
    );
  } else if (spaceType === SpaceType.DayOffice) {
    return (
      <OfficesFilter
        spaceTypeAmenities={spaceAmenities}
        locationAmenities={locationAmenities}
      />
    );
  }

  return (
    <PassesFilter
      spaceTypeAmenities={spaceAmenities}
      locationAmenities={locationAmenities}
    />
  );
}

function MeetingRoomFilter(props: SpaceTypeFilterProps) {
  const { spaceTypeAmenities, locationAmenities } = props;
  const mq = useMediaQuery();
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  if (isPreferredFlag) {
    return (
      <View style={styles.container}>
        <AmenitiesPicker
          spaceTypeAmenities={spaceTypeAmenities}
          locationAmenities={locationAmenities}
        />
        {/* <Spacer direction="row" size={mq.deviceQuery.mobile ? 8 : 16} />
        <StartEndTimePicker /> */}
        <Spacer direction="row" size={mq.deviceQuery.mobile ? 8 : 16} />
        <CapacityFilter />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {/* <StartEndTimePicker />
      <Spacer direction="row" size={mq.deviceQuery.mobile ? 8 : 16} /> */}
      <CapacityFilter />
      <Spacer direction="row" size={mq.deviceQuery.mobile ? 8 : 16} />
      <AmenitiesPicker
        offsetVertical={8}
        offsetHorizontal={-120}
        spaceTypeAmenities={spaceTypeAmenities}
        locationAmenities={locationAmenities}
      />
    </View>
  );
}

function OfficesFilter(props: SpaceTypeFilterProps) {
  const mq = useMediaQuery();
  const { spaceTypeAmenities, locationAmenities } = props;
  const isPreferredFlag = usePreferredSpaceFeatureFlag();
  if (isPreferredFlag) {
    return (
      <View style={styles.container}>
        <AmenitiesPicker
          spaceTypeAmenities={spaceTypeAmenities}
          locationAmenities={locationAmenities}
        />
        <Spacer direction="row" size={mq.deviceQuery.mobile ? 8 : 16} />
        <CapacityFilter />
      </View>
    );
  }
  return (
    <View style={styles.container}>
      <CapacityFilter />
      <Spacer direction="row" size={mq.deviceQuery.mobile ? 8 : 16} />
      <AmenitiesPicker
        offsetVertical={8}
        spaceTypeAmenities={spaceTypeAmenities}
        locationAmenities={locationAmenities}
      />
    </View>
  );
}

function PassesFilter(props: SpaceTypeFilterProps) {
  const { spaceTypeAmenities, locationAmenities } = props;

  return (
    <View style={styles.container}>
      <AmenitiesPicker
        offsetVertical={8}
        spaceTypeAmenities={spaceTypeAmenities}
        locationAmenities={locationAmenities}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
});

const amenitiesQuery = gql`
  query SpaceTypeFilterAmenities {
    MeetingRoomAmenities: amenities(spaceType: MeetingRoom) {
      id
      iconURL
      name
      code
    }
    DayPassAmenities: amenities(spaceType: DayPass) {
      id
      iconURL
      name
      code
    }
    DayOfficeAmenities: amenities(spaceType: DayOffice) {
      id
      iconURL
      name
      code
    }
    LocationAmenities: amenities {
      id
      iconURL
      name
      code
    }
  }
`;
